import React, { useState, useEffect, useContext } from 'react';
import styled from '@emotion/styled';
import { breakpoints, flex, hideScroll, resetButton, resetList, color } from '../../../../utils/style';
import Loader from '../../../shared/Loader';
import { predictionUserBonuses } from '../../../../utils/api/prediction';
import { StoreContext } from '../../../../store';
import { setProfileWiningBonuses } from '../../../../store/actions';
import { winningBonusesDate } from '../../../../utils/system';
import FullError from '../../../shared/FullError';
import dictionary from '../../../../utils/dictionary';

const Wrap = styled.ul`
  width: 100%;
  height: calc(100% - 20px);
  overflow-y: auto;
  
  ${resetList};
  ${hideScroll};
  
  & > li {
    margin-top: 20px;
  
    &:first-child {
      margin-top: 0;
    }
  }
  
  & > h2 {
    text-align: center;
  }
  
  @media (max-width: ${breakpoints.md}) {
    height: max-content;
  }
`;

const Row = styled.li`
  width: 100%;
  height: calc(50% - 10px);
  border-radius: 4px;
  background-color: ${color.astronaut};
  overflow: hidden;
  
  ${flex};
  justify-content: space-between;
  
  & .content {
    width: calc(100% - 200px);
    height: 100%;
    padding: 25px 20px;
    
    ${flex};
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    
    & > div {
      & > span {
        font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: ${color.white};
      }
    }
    
    & .title {
      & > span {
        font-weight: 500;
      }
    }
    
    & .date {
      & > span {
        opacity: 0.5;
      }
    }
  }
  
  & .money-panel {
    width: 200px;
    height: 100%;
    background-color: ${color.cello};
    
    ${flex};
    flex-direction: column;
    
    & .money {
      & > span {
        font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.73;
        letter-spacing: normal;
        color: ${color.supernova};
        
        & > span {
          text-transform: uppercase;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
    
    & .accept {
      ${resetButton};
      width: 120px;
      height: 44px;
      border-radius: 6px;
      background-color: ${color.darkPink};
      margin-top: 20px;
      
      font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: ${color.catskillWhite};
      
      &:hover {
        background-color: rgb(255,62,124);
      }
      
      &:active {
        background-color: rgba(255,62,124,0.8);
      }
    }
  }
  
  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;
    
    & .content {
      width: 100%;
      padding: 20px 16px;
      
      & .title {
        margin-bottom: 16px;
      }
      
      & .text {
        margin-top: 8px;
        opacity: 0.9;
      }
    }
    
    & .money-panel {
      width: 100%;
      padding: 16px;
      
      flex-direction: row;
      justify-content: center;
      
      & .money {
        & > span {
          font-size: 20px;
        }
      }
      
      & .accept {
        width: 93px;
        height: 44px;
        
        margin-top: 0;
      }
    }
  }
`;

const WinningList = () => {
  const [isLoaded, setIsLoaded] = useState(true);
  const [error, setError] = useState('');

  const [state, dispatch] = useContext(StoreContext);
  const { profile: { winning_bonuses } } = state;

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (winning_bonuses) {
      setIsLoaded(false);
      return () => { };
    }

    predictionUserBonuses()
      .then((json) => {
        if (!json) {
          setError(dictionary.formBagRequest);
          setIsLoaded(false);
          return false;
        }

        const { status, details, result } = json;
        if (!status) {
          setError(details);
          setIsLoaded(false);
          return false;
        }

        setProfileWiningBonuses(dispatch, result);
        setIsLoaded(false);
      })
      .catch((err) => {
        setIsLoaded(false);
        setError(dictionary.formBagRequest);
        // console.log('Winning request error: ', err);
      });
  }, []);

  if (error) return <FullError error={error} />;
  if (isLoaded) return <Loader />;

  return (
    <Wrap>
      { (!winning_bonuses || winning_bonuses.length === 0) && <h2>{dictionary.youStillNotHaveBonuses}</h2>}
      {
        winning_bonuses?.map((bonus) => {
          const { id, title, mail_text, ts, count } = bonus;
          return (
            <Row key={id}>
              <div className="content">
                <div className="title">
                  <span>{title}</span>
                </div>
                <div className="text">
                  {/* eslint-disable-next-line max-len */}
                  <span>{mail_text}</span>
                </div>
                <div className="date">
                  <span>{winningBonusesDate(ts)}</span>
                </div>
              </div>
              <div className="money-panel">
                <div className="money">
                  <span>+ {count} <span>thb</span></span>
                </div>
              </div>
            </Row>
          );
        })
      }
    </Wrap>
  );
};

export default WinningList;

import React from 'react';
import BetsTable from './Bets/BetsTable';
import WinningList from './Winning/WinningList';
import ChangePasswordForm from './AccountSettings/ChangePasswordForm';
import UploadAvatar from './AccountSettings/UploadAvatar';

const TabSwitcher = ({ tab }) => {
  switch (tab) {
    case 'bets': return <BetsTable />;
    case 'winning_bonuses': return <WinningList />;
    case 'account_settings': return (
      <>
        <UploadAvatar />
        <ChangePasswordForm />
      </>
    );
    default: return <></>;
  }
};

export default TabSwitcher;

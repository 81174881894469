import React from 'react';

const ProfileSvg = ({ width = 28, height = 28 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="69" height="69" viewBox="0 0 69 69">
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g transform="translate(-46 -540) translate(16 433) translate(30 107)">
              <circle cx="34.5" cy="34.5" r="34.5" fill="#374378" />
              <g>
                <path
                  stroke="#FEC314"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M21.848 21.848c-4.491 0-8.162-3.668-8.162-8.162 0-4.493 3.67-8.224 8.162-8.224 4.492 0 8.162 3.669 8.162 8.162 0 4.494-3.67 8.224-8.162 8.224zm12.745 16.386H9.103c-1 0-1.82-.819-1.82-1.82v-1.82c0-4.006 3.277-7.284 7.283-7.284H29.13c4.006 0 7.283 3.278 7.283 7.283v1.82c0 1.002-.82 1.821-1.82 1.821z"
                  transform="translate(13 13)"
                />
                <path d="M0 0L43.697 0 43.697 43.697 0 43.697z" transform="translate(13 13)" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ProfileSvg;

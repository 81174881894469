import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { breakpoints, flex, resetList, color, border } from '../../utils/style';
import IcomoonIcon from '../shared/IcomoonIcon';
import { freespinLogout } from '../../utils/api/freespin';
import { StoreContext } from '../../store';
import { setUser, setProfileMenu } from '../../store/actions';
import dictionary from '../../utils/dictionary';

const Wrap = styled.ul`
  ${resetList};

  width: 100%;
  height: 82px;
  background-color: ${color.bunting};
  margin-top: 16px;
  border-radius: ${border.borderRadius4};
  overflow: hidden;
  
  ${flex};
  justify-content: space-between;
  
  & * {
    outline: none;
    user-select: none;
  }
  
  & > li {
    height: 100%;
    width: 100%;
    border-left: 1px solid ${color.transparentWhite2};
    padding: 9px 10px 8px 10px;
    
    &:first-child {
      border-left: none;
    }
    
    &.active {
      background-color: rgba(255,255,255,0.1);
    }
    
    & > a {
      width: 100%;
      height: 100%;
      
      ${flex};
      flex-direction: column;
      
      font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.23;
      letter-spacing: normal;
      text-align: center;
      color: ${color.white};
      
      & svg {
        padding-bottom: 3px;
      }
      
      & .green {
        color: ${color.lightGreen};
      }
    }
  }
  
  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`;

const MobileMenu = () => {
  const [state, dispatch] = useContext(StoreContext);
  const { profile: { activeTab } } = state;

  const logout = (e) => {
    e.preventDefault();
    freespinLogout(() => { setUser(dispatch, ''); }, true);
  };
  const menuChange = (item) => {
    if (item === activeTab) return false;
    setProfileMenu(dispatch, item);
  };

  return (
    <Wrap>
      <li className={activeTab === 'bets' && 'active'}>
        <Link to="/profile/" onClick={() => menuChange('bets')}>
          <IcomoonIcon icon="bets" color={color.lightningYellow} size={30} />
          <span>Bets</span>
          <span className="green">(3/7)</span>
        </Link>
      </li>
      <li className={activeTab === 'winning_bonuses' && 'active'}>
        <Link to="/profile/" onClick={() => menuChange('winning_bonuses')}>
          <IcomoonIcon icon="winning-bonuses" color={color.lightningYellow} size={30} />
          <span>Winning bonuses</span>
        </Link>
      </li>
      <li className={activeTab === 'account_settings' && 'active'}>
        <Link to="/profile/" onClick={() => menuChange('account_settings')}>
          <IcomoonIcon icon="user-profile" color={color.lightningYellow} size={30} />
          <span>{dictionary.accountSettings}</span>
        </Link>
      </li>
      <li>
        <Link to="/" onClick={logout}>
          <IcomoonIcon icon="log-out" color={color.lightningYellow} size={30} />
          <span>{dictionary.logout}</span>
        </Link>
      </li>
    </Wrap>
  );
};

export default MobileMenu;

// Checking if Object Not Empty
import { freespinUploadAvatar } from './api/freespin';

export const isNotEmpty = (x) => {
  if (x instanceof Object) {
    return Object.entries(x).length > 0;
  }
  return !!x;
};

// Checking if Object Is Empty
export const isEmpty = (x) => !isNotEmpty(x);

// Convert Image to base64 code
export const convertImageToBase64 = (event, callback) => {
  const file = event.target.files[0];
  const reader = new FileReader();
  reader.onloadend = function () {
    // console.log('Result Image converted to base 64', reader.result);
    callback({ image: reader.result });
  };
  reader.readAsDataURL(file);
};

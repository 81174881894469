import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { StoreContext } from '../../store';
import ProfileSvg from '../../icons/svg/ProfileSvg';
import { breakpoints, color, flex, gradient } from '../../utils/style';
import Bonuses from '../Auth/Bonuses';
import Balance from '../Auth/Balance';
import ProfileDivider from '../shared/ProfileDivider';
import ProfileButton from './ProfileButton';
import IcomoonIcon from '../shared/IcomoonIcon';
import Menu from './Menu';
import PopupWindow, { invokePopup } from '../shared/PopupWindow';
import dictionary from '../../utils/dictionary';
import UserAvatar from '../../icons/avatar/UserAvatar';
import WithdrawPage from '../Content/Withdraw/WithdrawPage';

const Wrap = styled.div`
  width: 100%;
  max-width: 288px;
  height: auto;
  background-color: ${color.bunting};
  border-radius: 4px;
  padding-top: 20px;

  ${flex};

  & .container {
    width: 100%;
    height: 100%;
    max-width: 288px;
  }

  & * {
    user-select: none;
  }

  & .profile-values,
  & .profile-buttons {
    padding: 0 15px;
  }

  & .profile-head {
    width: 100%;

    ${flex};
    flex-direction: column;

    & span {
      margin-top: 10px;
    }
    & .profile-avatar {
      width: 69px;
      height: 69px;
      margin: 0;
      border-radius: 50rem;
      object-fit: cover;
    }
  }

  & .profile-values {
    ${flex};
    justify-content: space-between;
    padding-top: 10px;

    & div {
      margin: 0 0;
    }

    & .bonuses,
    & .balance {
      width: 50%;
    }

    & .balance {
      background-color: transparent;
      padding-left: 15px;

      & * {
        margin-left: 0;
        padding-left: 0;
      }
    }
  }

  & .profile-buttons {
    ${flex};
    justify-content: space-between;
    margin-top: 20px;

    .button {
      width: 120px;
      max-width: 100%;
      height: 44px;
      background-color: ${color.transparentWhite2};
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      &:hover {
        background: ${color.rose};
        background: ${gradient.roseBittersweet};
      }
    }
  }

  & .profile-menu-wrap {
    & .profile-menu {
      display: block;
      position: relative;
      background-color: transparent;
      margin: 30px 0;
      padding: 0;

      & .buttons {
        display: none;
      }
    }
  }

  @media (max-width: ${breakpoints.md}) {
    padding-bottom: 16px;
    max-width: none;

    & .profile-menu-wrap {
      display: none;
    }
  }
`;

const ProfilePanel = () => {
  const [state] = useContext(StoreContext);
  const {
    user: { userName },
  } = state;
  const { userAvatar } = state.profile;

  const openWithdraw = (e) => invokePopup(e, 'withdraw-popup');
  const depositClick = () => {
    if (window) window.location.href = '/banking/';
  };
  const copyUsername = () => {
    navigator.clipboard.writeText(userName);
    alert(dictionary.copiedToClipboard);
  };

  return (
    <>
      <Wrap>
        <div className="container">
          <div className="profile-head">
            {userAvatar ? <UserAvatar avatar={userAvatar} /> : <ProfileSvg width={69} height={69} />}
            <span onClick={copyUsername}>{userName ?? 'No Name'}</span>
          </div>
          <div className="profile-values">
            <Bonuses className="bonuses" />
            <ProfileDivider />
            <Balance className="balance" />
          </div>
          <div className="profile-buttons">
            <ProfileButton className="button" onClick={depositClick}>
              <IcomoonIcon className="" icon="purse" size={24} />
              <span>{dictionary.deposit}</span>
            </ProfileButton>
            <ProfileButton className="button" onClick={openWithdraw}>
              <IcomoonIcon className="" icon="money-hand" size={24} />
              <span>ข้อมูลการถอน</span>
            </ProfileButton>
          </div>
          <div className="profile-menu-wrap">
            <Menu />
          </div>
        </div>
      </Wrap>
    </>
  );
};

export default ProfilePanel;

import React, { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import ProfileSecondSvg from '../../../../icons/svg/ProfileSecondSvg';
import dictionary from '../../../../utils/dictionary';
import { border, breakpoints, color, flex, font, sizing } from '../../../../utils/style';
import { freespinGetAvatar, freespinUploadAvatar } from '../../../../utils/api/freespin';
import { convertImageToBase64, isEmpty, isNotEmpty } from '../../../../utils/ui-api-helper';
import { StoreContext } from '../../../../store';
import { setUserAvatar, uploadUserAvatar } from '../../../../store/actions';
import UserAvatar from '../../../../icons/avatar/UserAvatar';

import Loader from '../../../shared/Loader';

const Wrap = styled.div`
  width: 256px;
  height: 96px;
  border-radius: ${border.borderRadius14};
  margin: 25px 0;
  cursor: pointer;
  padding: 14px 15px;
  position: relative;
  background-color: ${color.transparentWhite05};

  &.has-error {
    margin-bottom: 40px;
  }
      
  @media (max-width: ${breakpoints.xs}) {
    margin: 25px auto;
  }

  ${flex};
  justify-content: flex-start;
  & input {
    position: absolute;
    width: 100%;
    height: 100%;
    text-indent: -999em;
    outline: none;
    color: transparent;
    background: transparent;
    cursor: pointer;
  }

  & svg,
  & .loader,
  & .profile-avatar {
    width: 69px;
    height: 69px;
    margin-right: 15px;
    border-radius: 50rem;
    object-fit: cover;
  }

  & h3 {
    margin: 0;
    padding: 0;

    font-family: ${font.default};
    font-size: ${sizing.normal};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    color: ${color.transparentWhite5};
  }

  .error-upload-avatar {
    position: absolute;
    top: 100px;
    font-size: 15px;
    left: 0;
  }

  @media (max-width: ${breakpoints.xs}) {
    width: calc(100% - 32px);
  }
`;

const UploadAvatar = () => {
  const [isLoaded, setIsLoaded] = useState(true);
  const [state, dispatch] = useContext(StoreContext);
  const [errorLoadAvatar, setErrorLoadAvatar] = useState(false);
  const { userAvatar } = state.profile;
  const { setAvatar } = state.profile;
  // console.log(state, setAvatar);

  useEffect(() => {
    if (!userAvatar) {
      // get user avatar - if it not exists
      setUserAvatar(dispatch)
        .then(() => window.location.reload());
      setIsLoaded(true);
    } else {
      // setUploadNewAvatar(userAvatar);
      setIsLoaded(false);
    }
  }, [userAvatar, setAvatar]);

  const onChangeAvatarHandler = (event) => {
    // Convert image to base64 format and send it to BE
    const avatarImage = event.target.files[0];
    const imageSize = ((avatarImage.size / 1024) / 1024).toFixed(4);
    const maxMbForImage = 0.7;

    if (imageSize >= maxMbForImage) {
      setErrorLoadAvatar(true);
      event.target.value = '';
      return false;
    }

    if (avatarImage && avatarImage.type.includes('image/')) {
      convertImageToBase64(event, freespinUploadAvatar);
      setErrorLoadAvatar(false);
    }

    dispatch(uploadUserAvatar());
  };

  return (
    <Wrap className={errorLoadAvatar ? 'has-error' : ''}>
      {isLoaded ? (
        <div className="loader">
          <Loader />
        </div>
      ) : userAvatar ? (
        <UserAvatar avatar={userAvatar} />
      ) : (
        <ProfileSecondSvg />
      )}
      {/* {userAvatar ? <UserAvatar avatar={userAvatar} /> : <ProfileSvg />} */}
      <h3>{dictionary.uploadAvatar}  <br /> (max size: 700 kb)</h3>
      <input type="file" onChange={(e) => onChangeAvatarHandler(e)} accept="image/*" />
      {
        errorLoadAvatar ? <span className="error-upload-avatar">Please upload correct picture</span> : ''
      }
    </Wrap>
  );
};

export default UploadAvatar;

import React, { useContext } from 'react';
import styled from '@emotion/styled';
import Menu from './Menu';
import { StoreContext } from '../../../store';
import TabSwitcher from './TabSwitcher';
import { border, breakpoints, color } from '../../../utils/style';
import MobileMenu from './MobileMenu';

const Wrap = styled.div`
  width: calc(100% - 288px - 34px);
  height: 520px; // TODO : after adding upload avatar API endpoint change this to 520px
  background-color: ${color.bunting};
  border-radius: 4px;
  padding: 30px 20px 40px 20px;

  @media (max-width: ${breakpoints.xs}) {
    &.bets {
      background-color: transparent;

      .mobile-menu + div {
        background-color: #18174f;
        border-radius: ${border.borderRadius4};
      }
    }

    &.account_settings {
      .mobile-menu {
        max-height: 50px;

        ul {
          box-shadow: none;
          padding-left: 0;
        }

        &:after {
          content: '';
          height: 6px;
          background-color: ${color.transparentWhite2};
          width: calc(100% - 32px);
          display: block;
          margin: auto;
          border-radius: 50rem;
        }
      }
    }
  }

  & * {
    user-select: none;
  }

  @media (max-width: ${breakpoints.md}) {
    width: 100%;
    height: max-content;
    padding: 26px 0 30px 0;
    margin-top: 16px;
    //background-color: ${color.bunting};
  }
`;

const Info = styled.div`
  width: 100%;
  height: calc(100% - 40px);
  margin-top: 30px;

  & .history-table {
    border-radius: ${border.borderRadius4};
    padding: 15px;
  }

  @media (max-width: ${breakpoints.md}) {
    height: max-content;
    margin-top: 16px;
  }
`;

const InfoPanel = () => {
  const [state] = useContext(StoreContext);
  const {
    profile: { menus, activeTab },
  } = state;

  return (
    <Wrap id="info-panel-index" className={activeTab}>
      <Menu items={menus[activeTab]} />
      <MobileMenu items={menus[activeTab]} />
      <Info>
        <TabSwitcher tab={activeTab} />
      </Info>
    </Wrap>
  );
};

export default InfoPanel;

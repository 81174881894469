import React, { useState, useContext } from 'react';
import styled from '@emotion/styled';
import { StoreContext } from '../../store';
import { setBalance } from '../../store/actions';
import { breakpoints, color } from '../../utils/style';
import { freespinBalance } from '../../utils/api/freespin';
import UpdateIcon from "../../icons/auth/UpdateIcon";

const GameBalanceComponent = styled.div`
  width: 144px;
  height: 44px;
  border-radius: 4px;
  background-color: ${color.bunting};
  padding-left: 10px;
  margin-right: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  span.value {
    color: ${color.lightningYellow};
  }

  .update {
    position: absolute;
    top: 19px;
    right: 15px;
    background: none;
    border: none;
    outline: none;
    display: inline-flex;
    align-items: center;
    width: 20px;
    height: 20px;
    padding: 0;
    cursor: pointer;
    &:hover, &:active {
      opacity: 0.6;
    }
    &.loading {
      cursor: default;
      opacity: 0.5;
      svg {
        animation:spin 4s linear infinite;
      }
    }
  }

  @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

  & .title {
    opacity: 0.5;
    font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: ${color.moonRaker};
    text-transform: uppercase;
  }

  & .balance {
    font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: ${color.supernova};
  }

  &.header {
    width: max-content;
    height: 100%;
    padding-left: 0;
    margin-right: 0;
    margin-left: 16px;

    & .balance {
      text-align: left;
    }
  }

  @media (max-width: ${breakpoints.md}) {
    margin-right: 0;
    margin-bottom: 20px;
  }
  @media (max-width: ${breakpoints.xs}) {
    margin-bottom: 0;
    margin-right: 10px;
  }
`;

const Balance = ({ className }) => {
  const [store, dispatch] = useContext(StoreContext);
  const { user: { balance } = {} } = store;
  const [balanceLoading, setBalanceLoading] = useState(false);

  // const [updateTime, setUpdateTime] = useState(new Date().getTime());

  // useEffect(() => {
  //   if (balance) return () => {};
  //
  //   freespinBalance()
  //     .then((json) => {
  //       setBalance(dispatch, json.balance ?? '0.00');
  //     })
  //     .catch((err) => {
  //       // console.log('Balance request: ', err);
  //     });
  // }, [updateTime]);
  //
  // // Updating balance every hour
  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setUpdateTime(new Date().getTime());
  //   }, 60000);
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);

  const updateContextBalance = () => {
    if (balance) return () => {};

    setBalanceLoading(true);

    freespinBalance()
      .then((json) => {
        setBalance(dispatch, json.balance ?? 'N/A');
        setBalanceLoading(false);
      })
      .catch((err) => {
        // console.log('Balance request: ', err);
        setBalanceLoading(false);
      });
  };

  return (
    <GameBalanceComponent className={className || ''}>
      <button
        className={balanceLoading ? 'update loading' : 'update'}
        aria-label="Update"
        onClick={() => updateContextBalance()}
      >
        <UpdateIcon />
      </button>
      <span className="title">การธนาคาร</span>
      <span className="value">{balance || 'N/A'} ฿</span>
    </GameBalanceComponent>
  );
};

export default Balance;
